/*
 |-----------------------------------------------------------------------------
 | components/atoms/Checkbox/Checkbox.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as ICheckbox from './types';

import styles from './Checkbox.module.scss';

const variants = {
	primary: 'primary',
	secondary: 'secondary',
};

const Checkbox: React.FC<ICheckbox.IProps> = ({
	className,
	hideLabel,
	isDisabled,
	isOptional,
	label,
	name,
	options,
	variant = 'primary',
}) => {
	const classes = classNames(
		get(styles, 'block'),
		get(styles, variants[variant]),
		{
			[get(styles, 'isDisabled')]: isDisabled,
		},
		className,
		'u-spacing'
	);

	// ray('Debug atom Checkbox:', {
	// 	className: className,
	// 	hideLabel: hideLabel,
	// 	isDisabled: isDisabled,
	// 	isOptional: isOptional,
	// 	label: label,
	// 	name: name,
	// 	options: options,
	// 	variant: variant,
	// }).red();

	return (
		<fieldset className={classes}>
			<legend
				className={
					hideLabel ? 'u-screen-reader' : get(styles, 'legend')
				}
			>
				{label}
			</legend>
			{map(options, (option, index) => (
				<label
					key={index}
					className={get(styles, 'option')}
					htmlFor={get(option, 'id')}
				>
					<span className={get(styles, 'label')}>
						{get(option, 'label')}
					</span>
					<input
						className={get(styles, 'control')}
						defaultValue={get(option, 'value')}
						disabled={isDisabled}
						id={get(option, 'id')}
						name={name}
						required={!isOptional}
						type="checkbox"
					/>
					<span className={get(styles, 'indicator')}></span>
				</label>
			))}
		</fieldset>
	);
};

export default Checkbox;
